import React from "react";

import { useConfig } from "gatsby-theme-advanced";

import { Body } from "../../theme";

import * as S from "./styles";

const AuthorSegment = (): JSX.Element | null => {
  const config = useConfig();

  if (!config.user) return null;

  const authorFullName = `${config.user.firstName} ${config.user.lastName}`;

  return null;
};

export default AuthorSegment;
